import { useContext } from 'react';

import { NikeI18nContext } from '@nike/i18n-react';

import './nav.css';
import { Languages } from '../../types/i18n/translations';
import { useLanguageContext } from '../../context/language';
type Props = {
  title: string;
};

/**
 * A navbar component used  throughout our app
 *
 * @param {string} title – the title of our lovely little application
 */
const Nav = ({ title }: Props) => {
    const [, setLanguage] = useLanguageContext();
    const { supportedLanguages } = useContext(NikeI18nContext);

    const handleLanguageChange = (tag: Languages) => {
        setLanguage(tag);
    };

    return (
        <nav>
            <div className='title brand'>
                <a className='title-link'>{title}</a>
            </div>

            <div className='language-select'>
                {supportedLanguages.map(item => {
                    return <button className='link english-button' key={item.tag}
                        onClick={() => handleLanguageChange(item.tag as Languages)}>{item.name}</button>;
                })}
            </div>
        </nav>
    );
};

export default Nav;
