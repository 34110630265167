import { FC } from 'react';
import { PortletCard } from '../shared/portlet-card';

export const MemberInfoCard: FC = () => {
    return <PortletCard
        imageLink='/static/member-info.png'
        imageTitle='Member Info'
        contentTitle='Member Info'
        content='Check customers details, orders, payment details etc'
        pathname='/portlets/memberinfo/implicit'
    />;
};
