import './index.css';

/**
 * This component is a loading spinner in true Nike style. it will shrink or grow to fill the space
 * it has, with the spinner centered in its parent.
 *
 * @param {object} props – a React Props object
 */
const Loading = () => {
    return (
        <div id='loading-container'>
            <svg style={{ isolation: 'isolate' }} viewBox='0 0 1000 1000' width='100pt' height='100pt'>
                <path
                    d=' M 206.961 283.909 C 113.832 352.459 53.327 407.637 25.444 449.443 C 4.749 483.899 -3.321 511.419 1.232 532.002 C 18.108 587.703 83.713 606.046 198.03 587.012 C 270.384 572.068 366.741 543.366 487.046 500.854 M 206.961 283.909 C 156.377 334.498 129.255 376.429 125.595 409.699 C 115.449 475.465 175.437 501.809 305.437 500.854 C 354.816 499.81 415.369 503.908 487.046 500.854'
                    fillRule='evenodd'
                    id='w'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.125' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.125' />
                </path>

                <path
                    d=' M 445.589 139.99 C 331.265 122.61 249.465 118.844 200.188 128.689 C 161.19 138.42 136.023 152.173 124.689 169.946 C 97.236 221.266 130.655 280.627 224.948 348.002 C 286.677 388.597 375.107 436.436 490.236 491.444 M 445.589 139.99 C 374.049 139.994 325.222 150.466 299.108 171.403 C 245.43 210.733 269.22 271.779 361.82 363.027 C 397.474 397.205 437.394 442.92 490.236 491.444'
                    fillRule='evenodd'
                    id='nw'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.25' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.25' />
                </path>

                <path
                    d=' M 716.091 206.961 C 647.541 113.832 592.363 53.327 550.557 25.444 C 516.101 4.749 488.581 -3.321 467.998 1.232 C 412.297 18.108 393.954 83.713 412.988 198.03 C 427.932 270.384 456.634 366.741 499.146 487.046 M 716.091 206.961 C 665.502 156.377 623.571 129.255 590.301 125.595 C 524.535 115.449 498.191 175.437 499.146 305.437 C 500.19 354.816 496.092 415.369 499.146 487.046'
                    fillRule='evenodd'
                    id='n'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.375' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.375' />
                </path>

                <path
                    d=' M 860.01 445.589 C 877.39 331.265 881.156 249.465 871.311 200.188 C 861.58 161.19 847.827 136.023 830.054 124.689 C 778.734 97.236 719.373 130.655 651.998 224.948 C 611.403 286.677 563.564 375.107 508.556 490.236 M 860.01 445.589 C 860.006 374.049 849.534 325.222 828.597 299.108 C 789.267 245.43 728.221 269.22 636.973 361.82 C 602.795 397.474 557.08 437.394 508.556 490.236'
                    fillRule='evenodd'
                    id='ne'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.5' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.5' />
                </path>

                <path
                    d=' M 793.039 716.091 C 886.168 647.541 946.673 592.363 974.556 550.557 C 995.251 516.101 1003.321 488.581 998.768 467.998 C 981.892 412.297 916.287 393.954 801.97 412.988 C 729.616 427.932 633.259 456.634 512.954 499.146 M 793.039 716.091 C 843.623 665.502 870.745 623.571 874.405 590.301 C 884.551 524.535 824.563 498.191 694.563 499.146 C 645.184 500.19 584.631 496.092 512.954 499.146'
                    fillRule='evenodd'
                    id='e'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.625' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.625' />
                </path>

                <path
                    d=' M 554.411 860.01 C 668.735 877.39 750.535 881.156 799.812 871.311 C 838.81 861.58 863.977 847.827 875.311 830.054 C 902.764 778.734 869.345 719.373 775.052 651.998 C 713.323 611.403 624.893 563.564 509.764 508.556 M 554.411 860.01 C 625.951 860.006 674.778 849.534 700.892 828.597 C 754.57 789.267 730.78 728.221 638.18 636.973 C 602.526 602.795 562.606 557.08 509.764 508.556'
                    fillRule='evenodd'
                    id='se'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.75' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.75' />
                </path>

                <path
                    d=' M 283.909 793.039 C 352.459 886.168 407.637 946.673 449.443 974.556 C 483.899 995.251 511.419 1003.321 532.002 998.768 C 587.703 981.892 606.046 916.287 587.012 801.97 C 572.068 729.616 543.366 633.259 500.854 512.954 M 283.909 793.039 C 334.498 843.623 376.429 870.745 409.699 874.405 C 475.465 884.551 501.809 824.563 500.854 694.563 C 499.81 645.184 503.908 584.631 500.854 512.954'
                    fillRule='evenodd'
                    id='s'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='-0.875' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='-0.875' />
                </path>

                <path
                    d=' M 139.99 554.411 C 122.61 668.735 118.844 750.535 128.689 799.812 C 138.42 838.81 152.173 863.977 169.946 875.311 C 221.266 902.764 280.627 869.345 348.002 775.052 C 388.597 713.323 436.436 624.893 491.444 509.764 M 139.99 554.411 C 139.994 625.951 150.466 674.778 171.403 700.892 C 210.733 754.57 271.779 730.78 363.027 638.18 C 397.205 602.526 442.92 562.606 491.444 509.764'
                    fillRule='evenodd'
                    id='sw'
                    fill='rgb(0,0,0)'>
                    <animate
                        attributeName='opacity'
                        dur='1s'
                        values='1;0.35'
                        repeatCount='indefinite'
                        begin='0' />
                    <animate
                        attributeName='fill'
                        dur='4s'
                        values='#A62732;#E5482C;#0075BA;#40376C'
                        repeatCount='indefinite'
                        begin='0' />
                </path>
            </svg>
        </div>
    );
};

export default Loading;
