import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Typography
} from '@material-ui/core';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

export type Props = {
    imageLink: string,
    imageTitle: string,
    contentTitle: string,
    content: string,
    pathname: string,
}

export const PortletCard: FC<Props> = ({
    imageLink,
    imageTitle,
    contentTitle,
    content,
    pathname,
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={process.env.PUBLIC_URL + imageLink}
                    title={imageTitle}
                />
                <CardContent>
                    <Typography gutterBottom variant='h5' component='h2'>
                        {contentTitle}
                    </Typography>
                    <Typography variant='body2' color='textSecondary' component='p'>
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size='small' color='primary'
                    onClick={() => { document.location.pathname = pathname; }}>
                    Visit
                </Button>
            </CardActions>
        </Card>
    );
};
