/*
This file contains translation data for the layout component. the objects below contain the
requisite information for Bodega to provide your app with translations, once they've been set up.
*/

const translations = {
    appTitle: {
        stringKey: 'app-title',
        primaryValue: 'CS Template App',
        description: 'Title of the app – also displayed on the Navbar',
    },
};

export default translations;
