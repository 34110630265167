import { FC } from 'react';
import { PortletCard } from '../shared/portlet-card';

export const GiftCardCard: FC = () => {
    return <PortletCard
        imageLink='/static/giftcard.png'
        imageTitle='Gift Card'
        contentTitle='Gift Cards'
        content='Check balance of Consumer Gift Cards, Check details, Resend...'
        pathname='/portlets/giftcard/implicit'
    />;
};
