import React, { useEffect, useMemo, useState } from 'react';

import { NikeI18nProvider } from '@nike/i18n-react';

import { LanguageContext } from './context';
import { Languages } from '../../types/i18n/translations';
import { resolveLanguage, storeLanguage } from './language-storage';
import {
    supportedLanguages,
    translations
} from './i18n';

/**
 * A provider for i18n settings and selected language.
 * On an app first loading it uses the next flow to resolve a language:
 *          try to load it from URL query param 'lang'(?lang=en=US) ->
 *          try to load it from localStorage ->
 *          as a fallback use a default language
 * To get all the i18n settings please use
 *          const { ... } = useContext(NikeI18nContext);
 * To set a current language please use
 *          const [language, setLanguage] = useLanguageContext();
 * @param children
 * @constructor
 */

export const Language: React.FC = ({ children }) => {
    const [language, setLanguage] = useState<Languages>(resolveLanguage);

    useEffect(() => {
        storeLanguage(language);
    }, [language]);

    return (
        <LanguageContext.Provider value={useMemo(() => [language, setLanguage], [language])}>
            <NikeI18nProvider
                currentLanguageTag={language}
                supportedLanguages={supportedLanguages}
                translations={translations}>
                {children}
            </NikeI18nProvider>
        </LanguageContext.Provider>
    );
};
