
import { createContext, useContext } from 'react';

import { UserClaims } from '@okta/okta-auth-js';

export const AthleteContext = createContext<UserClaims | undefined>(undefined);

export interface Context extends UserClaims {
}

export const useAthleteContext = (): Context => useContext(AthleteContext) as Context;
