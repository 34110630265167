import { useContext } from 'react';

import { NikeI18nContext } from '@nike/i18n-react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import './index.css';
import { CheckoutCard } from '../../checkout';
import { GiftCardCard } from '../../giftcard';
import { MemberInfoCard } from '../../member-info';
import { PromoCodeCard } from '../../promocode';
import { useAthleteContext } from '../../../context/athlete';
import WithLayout from '../../shared/withLayout';
import translations from './index.i18n';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1em',
    },
    productList: {
        listStyle: 'none',
    },
}));

/**
 * This is the primary component for our home view.
 *
 * path: '/'
 */
const Home = () => {
    const classes = useStyles();
    // i18nString is a function used to turn translation keys into translations
    const { i18nString } = useContext(NikeI18nContext);
    const userInfo = useAthleteContext();

    return (
        <div className={classes.root}>
            <WithLayout>
                {userInfo && (
                    <>
                        <h1 className='brand'>{`${i18nString(translations.greeting)}${userInfo.name}!`}</h1>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <GiftCardCard />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckoutCard />
                            </Grid>
                            <Grid item xs={4}>
                                <PromoCodeCard />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <MemberInfoCard />
                            </Grid>
                        </Grid>
                    </>
                )}
            </WithLayout>
        </div>
    );
};

export default Home;