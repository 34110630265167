/*
This file contains translation data for the home screen. the objects below contain the requisite
information for Bodega to provide your app with translations, once they've been set up.
*/

const translations = {
    greeting: {
        stringKey: 'greeting',
        primaryValue: 'Good day, ',
        description: 'a greeting for the logged-in user',
    },
};

export default translations;
