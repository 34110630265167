import { FC } from 'react';
import { PortletCard } from '../shared/portlet-card';

export const PromoCodeCard: FC = () => {
    return <PortletCard
        imageLink='/static/promocode.jpeg'
        imageTitle='Promocode'
        contentTitle='Promocode'
        content='Operations with promocodes'
        pathname='/portlets/promocode'
    />;
};
