import { OKTAConfig } from './types/config';

const AUTH_CALLBACK_URL = '/implicit/callback/';

/**
 * Application configuration
 */
export const Config: any = {

    AUTH_CALLBACK_URL,

    Okta: {
        issuer: window.__CONFIG__?.okta.issuer,
        clientId: window.__CONFIG__?.okta.clientId,
        redirectUri: window.location.origin + AUTH_CALLBACK_URL,
        tokenManager: {
            /**
             * each app should have it's own storage key based on the client id of the app
             */
            storageKey: `${window.__CONFIG__?.okta.clientId}.ui`,
        },
    } as OKTAConfig,

};
