import React, { createContext, useContext } from 'react';

import { Languages } from '../../types/i18n/translations';

export const initialLanguage: Languages = Languages.EN;

type Context = [Languages, React.Dispatch<React.SetStateAction<Languages>>];
/**
 * A context to inject selected language getter/setter.
 * Usage example:
 *  <Language>
 *      <MyCoolComponent>
 *  </Language>
 *
 *  inside your component you can use
 *      const [language, setLanguage] = useLanguageContext();
 *  to manage selected language state
 */
export const LanguageContext = createContext<Context>([initialLanguage, () => { }] as Context);

export const useLanguageContext = (): Context => useContext(LanguageContext) as Context;
