import { useContext } from 'react';

import { useOktaAuth } from '@okta/okta-react';

import { NikeI18nContext } from '@nike/i18n-react';

import './withLayout.css';
import Loading from './loading';
import Nav from './nav';
import translations from './withLayout.i18n';

type Props = {
  children: React.ReactNode;
};

/**
 * A higher-order component which we'll use for persistent elements in our app
 *
 * @param {React.Component} Contents – the content to go inside the layout
 */
const WithLayout = ({ children }: Props) => {
    const { i18nString } = useContext(NikeI18nContext);
    const { authState } = useOktaAuth();

    if (authState.isPending) return <Loading />;
  
    return (
        <>
            <Nav title={i18nString(translations.appTitle)} />
            <main>{children}</main>
        </>
    );
};

export default WithLayout;
