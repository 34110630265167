import React, { useEffect, useState } from 'react';

import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { AthleteContext } from './context';

/**
 * A read-only context for athlete information.
 * It will forget any user info when user isn't authenticated.
 * Update userInfo when authState or oktaAuth changes.
 * @param { children } props  – React props with children
 */
export const Athlete: React.FC = ({ children }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserClaims | undefined>();

    useEffect(() => {
        if (authState.isAuthenticated) {
            oktaAuth.getUser().then((info: UserClaims) => {
                setUserInfo(info);
            });
        } else {
            setUserInfo(undefined);
        }
    }, [authState, oktaAuth]);

    return (
        <AthleteContext.Provider value={userInfo}>
            {children}
        </AthleteContext.Provider>
    );
};

