import { FC } from 'react';
import { PortletCard } from '../shared/portlet-card';

export const CheckoutCard: FC = () => {
    return <PortletCard
        imageLink='/static/checkout.png'
        imageTitle='Checkout'
        contentTitle='Checkout'
        content='Checkout on behalf of customer...'
        pathname='/portlets/checkout'
    />;
};
