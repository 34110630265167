import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useCallback, useRef } from 'react';

import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import './app.css';
import { Athlete } from './context/athlete';
import { Config } from './config';
import { Language } from './context/language';
import Home from './components/views/home';

function App() {
    const oktaAuth = useRef(new OktaAuth(Config.Okta));

    const restoreOriginalUri = useCallback((oktaAuth: OktaAuth, originalUri: string) => {
        window.location.replace(originalUri);
    }, []);

    return (
        <BrowserRouter>
            <Language>
                <Security oktaAuth={oktaAuth.current} restoreOriginalUri={restoreOriginalUri}>
                    <Switch>
                        {/* this route is for Okta authentication purposes, */}
                        <Route path={Config.AUTH_CALLBACK_URL} component={LoginCallback} />
                        {/* the following are our routes */}
                        <SecureRoute path='/' exact>
                            <Athlete>
                                <Home />
                            </Athlete>
                        </SecureRoute>
                    </Switch>
                </Security>
            </Language>
        </BrowserRouter>
    );
}

export default App;